<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container" :style="DS_showInfoPadding({}, {padding: '0'})">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" :style="DS_showInfoPadding({padding: '20px'}, {padding: '20px 0 0'})">
            <div v-if="DS_showPageTitle" class="page-info-title">{{ $route.meta.title }}</div>
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" :style="DS_showInfoPadding({padding: '20px'}, {padding: '20px 0 0'})" >
            <div class="descriptions-header">
              <div class="descriptions-title">踏勘信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                  <tr class="descriptions-row">
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">所属公司</span>
                        <span class="descriptions-item-content">
                          {{ workOrder
                          && workOrder.createUser
                          && workOrder.createUser.distributor
                          && workOrder.createUser.distributor.name }}
                        </span>
                      </div>
                    </td>
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">踏勘人员</span>
                          <span class="descriptions-item-content">
                          {{ user && user.name  }}
                        </span>
                      </div>
                    </td>
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">房屋类型</span>
                        <span class="descriptions-item-content">
                          {{ roofTypeModel && roofTypeModel.name }}
                        </span>
                      </div>
                    </td>
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">朝向角度</span>
                        <span class="descriptions-item-content">
                          {{ workOrder && workOrder.angle  }}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr class="descriptions-row">
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">并网点距离</span>
                        <span class="descriptions-item-content">
                          {{ workOrder && showField(workOrder.distance, 'm') }}
                        </span>
                      </div>
                    </td>
                    <td class="descriptions-item" colspan="1">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">备注</span>
                        <span class="descriptions-item-content">
                          {{ workOrder && workOrder.remarks }}
                        </span>
                      </div>
                    </td>
                    <td class="descriptions-item" colspan="1"></td>
                  </tr>
                  <tr class="descriptions-row">
                    <td class="descriptions-item" colspan="3">
                      <div class="descriptions-item-container">
                        <span class="descriptions-item-label">房屋照片</span>
                        <span v-if="workOrder" class="descriptions-item-content">
                          <image-shows :imgs="viewImages(workOrder.houseImg)"></image-shows>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" :style="{padding: DS_showPageTitle ? '20px' : ''}">
            <div class="descriptions-header">
              <div class="descriptions-title">设计信息</div>
            </div>
            <div class="descriptions-view" v-if="metadata">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计状态</span>
                      <span class="descriptions-item-content">
                          {{ designStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"
                      v-if="metadata.state === 'FAIL'">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">审核不通过原因</span>
                      <span class="descriptions-item-content">
                          {{ metadata.msg && metadata.msg }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件块数</span>
                      <span class="descriptions-item-content">
                          {{ designData && designData.number }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件尺寸</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.size,'m') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">组件功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.power,'w') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">逆变器功率</span>
                      <span class="descriptions-item-content">
                          {{ designData && showField(designData.npower,'w') }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计文件</span>
                      <span class="descriptions-item-content">
                        <file-list :design-data="designData"></file-list>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 审核信息 -->
          <div v-if=" metadata && metadata.state === 'EXAMINE' && checkPermission('sj:examine')"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">审核</div>
            </div>
            <div class="descriptions-view">
              <el-button type="primary" size="mini" @click="examineInfo">审核</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {designExamine, getDesignById, getInfoFarmer} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {designStatusMapper} from "@/utils/helper";
import {examineMsg} from "@/pageInteraction/examine/ExamineMsg";
import checkPermission from "@/utils/checkPermission";
import FileList from "@/components/FileList";
import {detailsShow} from "@/utils/commonMix";
import ImageShows from "@/components/ImageShows.vue";
export default {
  name: "DesignInfo",
  components: {ImageShows, FileList, PageHeader},
  mixins: [detailsShow],
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    },
    apiPrefix: {
      type: String,
      default(){
        return ''
      }
    },
    farmerId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  data(){
    return {
      getData: getDesignById,
      id: '',
      metadata: null,

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    checkPermission,
    designStatusMapper,
    viewImages,
    showField(val, spec=''){
      if (val) {
        return val + spec
      }
      return ''
    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     * 审核
     */
    examineInfo(){
      examineMsg(info => {
        const params = {
          designId: this.id,
          msg: info.msg,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        designExamine(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.replace('/admin/design')
          }, 500)
        }).finally(() => {
          loading.close()
        })

      })
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        if (this.apiPrefix){
          getInfoFarmer(this.apiPrefix, this.farmerId).then(res => {
            this.metadata = res
            //
            this.farmer = this.metadata?.farmer
            this.workOrder = this.metadata?.workOrder
            this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
            this.user = this.metadata?.workOrder?.user
            this.designData = this.metadata?.designData
          })
        } else {
          this.getData(this.id).then(res => {
            this.metadata = res
            //
            this.farmer = this.metadata?.farmer
            this.workOrder = this.metadata?.workOrder
            this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
            this.user = this.metadata?.workOrder?.user
            this.designData = this.metadata?.designData
          })
        }
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>